export function getQueryString(page = 1, filters = {}, sortBy = [], sortDesc = []) {
  let params= {
    page: page,
    order: {},
    ...filters,
  }

  if (sortBy.length > 0) {
    params.order[sortBy[0]] = sortDesc[0] ? 'desc' : 'asc'
  }

  return create(params)
}

export function create(data, parentKey = '') {
  return Object.keys(data)
    .map(key => {
      const value = data[key]
      if (value === undefined || value === '' || value === null) return

      if (typeof value === 'object') {
        return create(value, key)
      }
      if (parentKey) {
        if (key.match(/\d+/)) {
          return `${parentKey}=${encodeURIComponent(value)}`
        }
        else {
          return `${parentKey}[${key}]=${encodeURIComponent(value)}`
        }
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .filter(value => value).join('&')
}

export function getQueryAsArray(query, param) {
  return Array.isArray(query[param]) ? query[param] : [query[param]]
}

export function getQueryAsBoolean(query, param) {
  const value = query[param] ?? null

  if (value === null || value.trim() === '') {
    return null
  }

  return String(query[param]).toLowerCase() === 'true'
}
